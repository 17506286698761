import { User } from '@sentry/browser'
import env from 'lib/env'
import { compact } from 'lodash'
import {
  Booking,
  BookingLocationFieldsFragment,
  CurrentUser,
  Group,
  ManagedGroup,
  ManagedUser,
  Maybe,
} from 'types/graphql'

type GroupHostProps = {
  customDomain?: Maybe<string>
  slug: string
  authProvider?: Maybe<string>
}
type GroupSlugType = {
  slug: string
}

const TICKET_REGEX = /[webWEB]{3}-[0-9]{3,5}/

const getLocation = () => {
  if (typeof window === 'undefined') {
    return {
      href: '/',
      host: '',
    }
  } else {
    return window.location
  }
}

export const headerLogoLink = (url?: Maybe<string>, locale = 'en') => {
  return url || `/${locale}`
}

export type MemberUrlProps = {
  group?: GroupSlugType
  userId?: string
  user?: Pick<User | ManagedUser, 'mentor'>
}

export const memberUrl = (
  group?: MemberUrlProps['group'],
  userId?: MemberUrlProps['userId'],
  user?: MemberUrlProps['user']
) => {
  let path = ''

  if (group && group.slug === 'bptn-networking') {
    path = `/members` // @TODO: get value of feature flag instead?
  } else if (!user || user.mentor) {
    path = `/mentors`
  } else {
    path = `/mentees`
  }

  if (userId) {
    path = `${path}/${userId}`
  }
  return path
}

export type ProfileUrlUserProps = {
  mentor: (User | CurrentUser)['mentor']
  slug?: Maybe<(User | CurrentUser)['slug']>
  id: (User | CurrentUser)['id']
  group?: Maybe<Pick<Group | ManagedGroup, 'slug'>>
}

//TODO this is being passed a group as user?
export const profileUrl = (
  user?: ProfileUrlUserProps,
  locale = 'en',
  group = undefined
) => {
  if (!user) return getLocation().href

  if (!('slug' in user) && !('id' in user)) {
    return getLocation().href
  }

  const userUrl = user.slug || user.id

  if (user.mentor) {
    return `/${locale}${memberUrl(user.group || group, userUrl)}`
  }
  return `/${locale}/mentees/${userUrl}`
}

export const parseHost = (host?: string) => {
  if (!host) {
    return null
  }

  const ticket = (host.match(TICKET_REGEX) || [])[0]

  return ticket || null
}

export const groupHost = (
  group?: Maybe<GroupHostProps>,
  isMentorlyAdmin?: boolean
) => {
  if (!group) {
    return env.groupDomain('marketplace')
  }

  if (env.development || env.staging || isMentorlyAdmin) {
    const ticket = parseHost(getLocation().host)

    const subDomain = ticket ? group.slug + '.' + ticket : group.slug

    return `http://${subDomain}.${env.mainHost}`
  }

  if (group.customDomain) {
    return `https://${group.customDomain}`
  }

  if (group.slug) {
    return env.groupDomain(group.slug)
  }

  return env.clientDomain
}

export const baseUrl = (group?: GroupHostProps, locale?: string) => {
  return `${groupHost(group)}/${locale}`
}

type conferenceUrlProps = {
  booking: Pick<Booking, 'id'>
  group: Pick<Group, 'meetingProvider'>
  locale: string
}
export const conferenceUrl = ({
  booking,
  group,
  locale = 'en',
}: conferenceUrlProps) => {
  const provider = group?.meetingProvider

  switch (provider) {
    case 'jitsi_external':
      return `https://meet.jit.si/${booking.id}`
    case 'jitsi_internal':
      return `/${locale}/conferences/${booking.id}/jitsi`
    default:
      return `/${locale}/conferences/${booking.id}/jitsi`
  }
}

export const parseUrl = (link: string) => {
  try {
    const url = new URL(link)
    return url.hostname + (url.pathname.length > 1 ? '/...' : '')
  } catch (err) {
    console.error(err)
    return null
  }
}

export const sessionUrl = (
  booking: { group: GroupHostProps; id: Booking['id'] },
  locale: string,
  group: GroupHostProps
) => {
  const sessionGroup = group || booking.group
  return `${baseUrl(sessionGroup, locale)}/sessions/${booking.id}`
}

export const sessionIndexUrl = (locale: string, isDashboard: boolean) => {
  const suffix = isDashboard ? 'dashboard/sessions' : 'personal'
  return `/${locale}/${suffix}`
}

export const sessionsHome = (
  group: GroupHostProps,
  locale: string,
  isDashboard: boolean
) => {
  const path = isDashboard ? 'dashboard/sessions' : 'personal'
  return `${baseUrl(group, locale)}/${path}`
}

export const sessionEdit = (
  booking: {
    id: Pick<Booking, 'id'>
    group: GroupHostProps
  },
  locale: string,
  isDashboard: boolean
) => {
  const path = sessionsHome(booking.group, locale, isDashboard)
  return `${path}/edit?id=${booking.id}`
}

export const calendarProviderUrl = (provider: string) => {
  return {
    microsoft: 'https://outlook.live.com/calendar/',
    google: 'https://calendar.google.com/calendar/u/',
  }[provider]
}

export const addressFromLocation = (
  location?: Maybe<BookingLocationFieldsFragment>
) => {
  if (!location) return {}

  const {
    fullName,
    name,
    address,
    thoroughfare,
    administrativeArea,
    locality,
    country,
  } = location || {}

  const fullAddress =
    address ||
    compact([thoroughfare, administrativeArea, locality, country]).join(', ')

  const addressString = compact([fullName || name, fullAddress]).join(', ')

  const mapUrl =
    'https://google.com/maps/search/?api=1&query=' + encodeURI(addressString)

  return { fullName, addressString, mapUrl, name }
}

export type SocialLoginActions = 'signin' | 'signup'

type socialLoginUrlProps = {
  type: string
  action: SocialLoginActions
  group?: Maybe<GroupHostProps>
}

export const socialLoginUrl = ({
  type,
  action,
  group,
}: socialLoginUrlProps) => {
  let href = process.env.NEXT_PUBLIC_API_URL
  let ticket = null

  if (typeof window !== 'undefined') {
    ticket = parseHost(window?.location?.host)
  }

  href += `/auth/${type}?action=${action}`
  if (group) {
    href += `&group=${group.slug}`
  }

  if (ticket) {
    href += `&ticket=${ticket}`
  }

  if (group?.authProvider === 'google') {
    //TODO pick scope based on group config
    const scopes = encodeURIComponent('email profile openid calendar')

    href += `&scope=${scopes}`
  }

  if (env.development) {
    href += `&development=true`
  }

  return href
}

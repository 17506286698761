import classNames from 'classnames'
import { Facebook, Instagram } from 'components/icons/SocialIcon'
import { useCurrentGroup } from 'lib/GroupContext'
import { VFC } from 'react'

export type SocialLinksProps = {
  className?: string
}

const SocialLinks: VFC<SocialLinksProps> = ({ className }) => {
  const { marketplace } = useCurrentGroup()
  return (
    <div className={classNames('flex justify-center sm:space-x-2', className)}>
      <a
        href={`https://facebook.com/${
          marketplace ? 'mentorly' : 'mentorlyenterprise'
        }`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Facebook size={20} className="hidden sm:block" />
        <Facebook size={60} className="sm:hidden block mr-6" />
      </a>
      <a
        href="https://instagram.com/mentorlyofficial"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram size={20} className="hidden sm:block" />
        <Instagram size={60} className="sm:hidden block" />
      </a>
    </div>
  )
}

export default SocialLinks

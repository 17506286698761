import { FeatureGroupType, getFeatureFlag } from 'components/Feature'
import env from 'lib/env'
import { Maybe } from 'types/graphql'

export const allowSignUpGroupUser = (
  group?: Maybe<FeatureGroupType>,
  user?: { mentorlyAdmin: boolean }
) => {
  if (!group) {
    return false
  }

  if (!env.production || user?.mentorlyAdmin) return true

  return getFeatureFlag(group, 'signUp')
}

import classNames from 'classnames'
import { ButtonLink } from 'components/Button'
import { contrastBW } from 'lib/color'
import { useCurrentGroup } from 'lib/GroupContext'
import { allowSignUpGroupUser } from 'lib/signUp'
import { useCurrentUser } from 'lib/UserContext'
import Link from 'next/link'
import { FC, VFC } from 'react'
import { useIntl } from 'react-intl'
import { GroupEssentialsFieldsFragment } from 'types/graphql'

type SignUpProps = {
  children: VFC
}
const SignUp: FC<SignUpProps> = ({ children }) => {
  const { formatMessage, locale } = useIntl()
  const { currentGroup, loading: loadingGroup } = useCurrentGroup()
  const { currentUser, loading: loadingUser } = useCurrentUser()

  if (loadingGroup || loadingUser || !currentGroup) return null // must be a group to sign-up to

  const signUp = allowSignUpGroupUser(currentGroup, currentUser)

  const url = `/${locale}/sign-up`
  const label = formatMessage({ id: 'button.signUp' })

  return signUp ? children(url, label) : null
}

const SignUpLink = () => {
  return <SignUp>{(url, label) => <Link href={url}>{label}</Link>}</SignUp>
}

type SignUpButtonProps = {
  onClick?: () => void
  className?: string
  group?: GroupEssentialsFieldsFragment
}
const SignUpButton: FC<SignUpButtonProps> = ({ onClick, className, group }) => {
  return (
    <SignUp>
      {(url, label) => (
        <div
          className={classNames('mr-3 inline-block', className)}
          onClick={onClick}
        >
          <Link href={url} passHref>
            <ButtonLink
              variant={
                contrastBW(group?.styles?.backgroundColor || 'yellow') ===
                'white'
                  ? 'invertedPrimary'
                  : 'primary'
              }
              testId="signup-header-button"
            >
              {label}
            </ButtonLink>
          </Link>
        </div>
      )}
    </SignUp>
  )
}

export { SignUpLink, SignUpButton }

import { VFC } from 'react'

import { SocialIconProps } from '.'

const Instagram: VFC<Omit<SocialIconProps, 'type'>> = ({
  size = 18,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M12 3c2.9 0 3.3 0 4.4.1 1.1 0 1.7.2 2 .4.5.2.9.4 1.3.8s.6.8.8 1.3c.2.4.3 1 .4 2 .1 1.1.1 1.5.1 4.4s0 3.3-.1 4.4c0 1.1-.2 1.7-.4 2-.2.5-.4.9-.8 1.3s-.8.6-1.3.8c-.4.2-1 .3-2 .4-1.1.1-1.5.1-4.4.1H7.6c-1.1 0-1.7-.2-2-.4-.5-.2-.9-.4-1.3-.8s-.6-.8-.8-1.3c-.2-.4-.3-1-.4-2C3 15.3 3 14.9 3 12V7.6c0-1.1.2-1.7.4-2 .2-.6.5-1 .8-1.4.4-.3.8-.6 1.3-.8.4-.2 1-.3 2-.4H12m0-2c-3 0-3.4 0-4.5.1-1.2.1-2 .2-2.7.5-.7.3-1.3.6-2 1.2-.6.6-1 1.2-1.3 1.9-.2.8-.4 1.6-.4 2.8C1 8.6 1 9 1 12s0 3.4.1 4.5c.1 1.2.2 2 .5 2.7.3.7.7 1.3 1.3 1.9.6.6 1.2 1 1.9 1.3.7.3 1.5.5 2.7.5 1.1.1 1.5.1 4.5.1s3.4 0 4.5-.1c1.2-.1 2-.2 2.7-.5.7-.3 1.3-.7 1.9-1.3.6-.6 1-1.2 1.3-1.9.3-.7.5-1.5.5-2.7.1-1.1.1-1.5.1-4.5s0-3.4-.1-4.5c-.1-1.2-.2-2-.5-2.7-.3-.7-.7-1.3-1.3-1.9-.6-.6-1.2-1-1.9-1.3-.7-.3-1.5-.5-2.7-.5C15.4 1 15 1 12 1zM12 6.4c-3.1 0-5.6 2.5-5.6 5.6s2.5 5.6 5.6 5.6 5.6-2.5 5.6-5.6-2.5-5.6-5.6-5.6zm0 9.3c-2 0-3.7-1.6-3.7-3.7S10 8.3 12 8.3s3.7 1.6 3.7 3.7-1.7 3.7-3.7 3.7z" />
    <circle cx="17.9" cy="6.1" r="1.3" />
  </svg>
)

export default Instagram

import { useApolloClient } from '@apollo/client'
import { Maybe } from 'types/graphql'

import doLogin from './login'
import doLogout from './logout'

const useAuth = () => {
  const client = useApolloClient()

  const login = async (token?: string, domain?: Maybe<string>) => {
    doLogin(token, domain)
    await client.resetStore()
  }

  const logout = async (domain?: Maybe<string>) => {
    doLogout(domain)
    await client.resetStore()
  }

  return { login, logout }
}

export default useAuth

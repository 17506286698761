export type ReadOnlyType = { active: boolean; fields: string[] }
export type FormPermissionType = { [x: string]: ReadOnlyType }
export type FlagResultType = boolean | number | FormPermissionType
export type FlagDefinitionType = FlagResultType | ((context: any) => any)
export type FeatureConfigType = Record<
  string,
  Record<string, FlagDefinitionType>
>
import userHasTags, { User } from 'lib/userHasTags'

//features that apply to all sxsw and sxsw-edu groups
const sxswGeneral = {
  bookingConversation: false,
  'calendar.socialSync': false,
  loginActions: false,
  manualWeeklyRecurringAvailabilities: false,
  'menu.externalUrlLink': true,
  'menu.schedule': true,
  messaging: true,
  onboarding: false,
  readOnlyFields: (user: User) => ({
    userProfile: {
      active: user.mentor,
      fields: [
        'name',
        'skills',
        'newProfileImage',
        'description',
        'welcomeMessage',
        'company',
        'role',
        'website',
        'discipline',
        'subdisciplines',
        'slug',
      ],
    },
    userSettings: {
      active: user.mentor,
      fields: ['name', 'contactEmail', 'externalId'],
    },
  }),
  sessionTimer: true,
  showAutoAcceptBookingsToggle: true,
  'sidebar.messaging': true,
  signUp: false,
  socialLogin: false,
  suggestTime: false,
  userCompany: true,
  userDescription: true,
  userExperience: false,
  userLanguages: false,
  userShare: false,
  userSubdisciplines: true,
  userWelcomeMessage: true,
}

const features: FeatureConfigType = {
  groupWithTimer: {
    sessionTimer: true,
  },
  afbd: {
    defaultRoleIsMentor: false,
    onboarding: false,
    roleSelection: false,
  },
  'artists-up': {
    onboarding: false,
  },
  'artists-up-art-in-public-places': {
    onboarding: false,
  },
  'artists-up-covid-relief-hub': {
    onboarding: false,
  },
  'bptn-mentoring': {
    languageToggle: false,
    socialLogin: false,
  },
  'bptn-networking': {
    bookUser: false,
    defaultRoleIsMentor: true,
    languageToggle: false,
    menteeAdoptionChart: false,
    'menu.schedule': false,
    onlyPeers: true,
    roleSelection: false,
    'sidebar.availabilities': false,
    'sidebar.resources': false,
    socialLogin: false,
    tagLimit: 1,
  },
  cmi: {
    onboarding: false,
  },
  creativemanitoba: {
    onboarding: false,
  },
  default: {
    bookingConversation: true,
    bookUser: true,
    'calendar.socialSync': true,
    defaultRoleIsMentor: false,
    languageToggle: true,
    loginActions: true,
    manualWeeklyRecurringAvailabilities: true,
    menteeAdoptionChart: true,
    'menu.about': false,
    'menu.blog': true,
    'menu.caseStudies': true,
    'menu.dashboard': true,
    'menu.externalUrlLink': false,
    'menu.faq': true,
    'menu.home': true,
    'menu.inKindFund': false,
    'menu.marketplace': true,
    'menu.mentors': true,
    'menu.pending': true,
    'menu.pricing': true,
    'menu.schedule': false,
    messaging: true,
    onboarding: true,
    onlyPeers: false,
    roleSelection: true,
    sessionTimer: false,
    showAutoAcceptBookingsToggle: false,
    'sidebar.availabilities': true,
    'sidebar.help': true,
    'sidebar.home': true,
    'sidebar.matching': true,
    'sidebar.members': true,
    'sidebar.messaging': true,
    'sidebar.payments': true,
    'sidebar.productboard': true,
    'sidebar.profile': true,
    'sidebar.program': true,
    'sidebar.reporting': true,
    'sidebar.resources': true,
    'sidebar.sessions': true,
    'sidebar.settings': true,
    'sidebar.surveys': true,
    'sidebar.viewProfile': true,
    'sidebar.viewSite': true,
    signUp: true,
    socialLogin: true,
    suggestTime: true,
    tagLimit: 99,
    userCompany: false,
    userDescription: true,
    userExperience: true,
    userLanguages: true,
    userShare: true,
    userSkills: true,
    userSubdisciplines: true,
    userWelcomeMessage: true,
    preventEditAvailability: false,
    preventEditEvents: false,
    readOnlyFields: () => ({}),
  },
  dev: {
    'calendar.socialSync': true,
    'menu.schedule': true,
    showAutoAcceptBookingsToggle: true,
    tagLimit: 1,
  },
  havas: {},
  marketplace: {
    'menu.about': true,
    'menu.inKindFund': true,
    sessionTimer: true,
    showAutoAcceptBookingsToggle: false,
    'sidebar.resources': false,
  },
  mit: {
    onboarding: false,
    suggestTime: true,
  },
  motionind: {
    'sidebar.matching': true,
    signUp: false,
  },
  osen: {
    onboarding: false,
  },
  pmi: {
    sessionTimer: true,
  },
  princestrustcanada: {
    userShare: false,
    userCompany: true,
  },
  qa: {
    'menu.schedule': true,
    suggestTime: false,
  },
  revolutionher: {
    roleSelection: false,
  },
  snap: {
    'menu.externalUrlLink': true,
  },
  startupglobal: {
    onboarding: false,
    roleSelection: false,
    signUp: false,
    suggestTime: false,
  },
  startupwomen: {
    onboarding: false,
    roleSelection: false,
    suggestTime: false,
  },
  'sxsw-2022': {
    ...sxswGeneral,
    'menu.pending': false,
    userSkills: false,
    preventEditEvents: ({ user, isActingAsMentor }) =>
      isActingAsMentor &&
      userHasTags(user, ['Interactive', 'Film', 'Music', 'Convergence']),
  },
  'sxsw-staging-2022': {
    ...sxswGeneral,
    'menu.pending': false,
    userSkills: false,
    preventEditEvents: ({ user, isActingAsMentor }) =>
      isActingAsMentor &&
      userHasTags(user, ['Interactive', 'Film', 'Music', 'Convergence']),
  },
  'sxswedu-2022': {
    ...sxswGeneral,
    userSkills: true,
    preventEditEvents: ({ user, isActingAsMentor }) =>
      isActingAsMentor && userHasTags(user, ['in person']),
  },
  'sxswedu-staging-2022': {
    ...sxswGeneral,
    userSkills: true,
    preventEditEvents: ({ user, isActingAsMentor }) =>
      isActingAsMentor && userHasTags(user, ['in person']),
  },
  mf: {
    roleSelection: false,
    sessionTimer: false,
  },
  test: {
    'calendar.socialSync': true,
    languageToggle: true,
    'menu.schedule': true,
    messaging: true,
    'sidebar.messaging': true,
    showAutoAcceptBookingsToggle: true,
    userShare: true,
    userSubdisciplines: true,
    preventEditEvents: ({ user, isActingAsMentor }) =>
      isActingAsMentor && userHasTags(user, ['preventEditEvents']), //keep for tests
  },
  uofa: {
    sessionTimer: true,
  },
}

export default features

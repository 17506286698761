import { Maybe } from 'types/graphql'

type GroupInfoType = {
  groupId: Maybe<string>
  branch: Maybe<string>
  root: boolean
}

export const parseDomain = (
  host: string,
  root: string,
  forceCustomDomain = false
): GroupInfoType => {
  if (forceCustomDomain || !host.endsWith(root)) {
    return { root: false, groupId: host, branch: null }
  }

  const parts = host.split('.')

  if (parts[0] === 'www') {
    parts.shift()
  }

  if (parts.length === 2) {
    return { groupId: null, branch: null, root: true }
  }

  if (parts.length === 4) {
    return { groupId: parts[0], branch: parts[1], root: false }
  }

  if (parts[0].startsWith('web-')) {
    return { groupId: null, branch: parts[0], root: true }
  }

  return { groupId: parts[0], branch: null, root: false }
}

export const stagingGroupDomain = (slug: string) => {
  const root = 'mentorly.dev'

  if (window.location) {
    const domainParts = parseDomain(window.location.host, root)

    if (domainParts.branch) {
      return `https://${slug}.${domainParts.branch}.${root}`
    }
  }

  return `https://${slug}.${root}`
}

import { Maybe } from 'types/graphql'

import { stagingGroupDomain } from './envUtils'

enum Environment {
  development = 'development',
  staging = 'staging',
  production = 'production',
  sandbox = 'sandbox',
}

type envConfigType = {
  development?: boolean
  staging?: boolean
  production?: boolean
  env: string
  clientDomain: string
  legacyDomain: string
  cookieDomain: Maybe<string>
  groupDomain: (slug: string) => string
  graphqlUrl: string
  mainHost: string
  tokenName: string
  logRocketID?: string
  intercomAppId?: string
  forceCustomDomain: boolean
}

let env = Environment.development

if (process.env.NEXT_PUBLIC_BASE_URL === 'https://mentorly.co') {
  env = Environment.production
}

if (process.env.NEXT_PUBLIC_BASE_URL === 'https://mentorly.dev') {
  env = Environment.staging
}

if (process.env.NEXT_PUBLIC_BASE_URL === 'https://mentorly.dlataas.com') {
  env = Environment.sandbox
}

const graphqlUrl = `${process.env.NEXT_PUBLIC_API_URL}/graphql`

const envConfig: Record<Environment, envConfigType> = {
  development: {
    development: true,
    env: Environment.development,
    clientDomain: 'http://localhost:3010',
    legacyDomain: 'http://localhost:3030',
    cookieDomain: null,
    groupDomain: (slug: string) => `http://${slug}.localtest.me:3010`,
    graphqlUrl: graphqlUrl,
    mainHost: 'localtest.me:3010',
    tokenName: 'token',
    logRocketID: 'nq434y/staging-ziuka',
    intercomAppId: 'jh99prcy',
    forceCustomDomain: false,
  },
  staging: {
    staging: true,
    env: 'development',
    clientDomain: 'https://mentorly.dev',
    legacyDomain: 'https://mentorly.dev',
    cookieDomain: 'mentorly.dev',
    groupDomain: stagingGroupDomain,
    graphqlUrl: graphqlUrl,
    mainHost: 'mentorly.dev',
    tokenName: 'staging-token',
    logRocketID: 'nq434y/staging-ziuka',
    intercomAppId: 'jh99prcy',
    forceCustomDomain: false,
  },
  production: {
    production: true,
    env: 'production',
    clientDomain: 'https://mentorly.co',
    legacyDomain: 'https://mentorly.co',
    cookieDomain: 'mentorly.co',
    groupDomain: (slug: string) => `https://${slug}.mentorly.co`,
    graphqlUrl: graphqlUrl,
    mainHost: 'mentorly.co',
    tokenName: 'token',
    logRocketID: 'nq434y/production',
    intercomAppId: 'xvy5mqyt',
    forceCustomDomain: false,
  },
  sandbox: {
    staging: true,
    env: 'development',
    clientDomain: 'https://mentorly.dlataas.com',
    legacyDomain: 'https://mentorly.dlataas.com',
    cookieDomain: 'mentorly.dlataas.com',
    groupDomain: stagingGroupDomain,
    graphqlUrl: 'https://mentorly-api.dlataas.com/graphql',
    mainHost: 'dlataas.com',
    tokenName: 'token',
    logRocketID: undefined,
    intercomAppId: undefined,
    forceCustomDomain: true,
  },
}

const currentConfig = envConfig[env]

export default currentConfig

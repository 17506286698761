import cookie from 'js-cookie'
import { Maybe } from 'types/graphql'

import { socialLoginUrl } from './urls'

type GroupType = {
  authProvider?: Maybe<string>
  slug: string
}

export const oauthProviderForGroup = (group?: GroupType) => {
  if (!group) {
    return null
  }

  return group.authProvider
}

export const authUrl = (group?: GroupType) => {
  const provider = oauthProviderForGroup(group)

  if (provider) {
    return socialLoginUrl({ group: group, type: provider, action: 'signin' })
  }

  return null
}

const setCookies = (token: string, domain?: string) => {
  const cookieName = process.env.NEXT_PUBLIC_TOKEN_NAME || 'token'

  cookie.set(cookieName, token, {
    domain: domain,
    expires: 14,
  })

  cookie.set('signedin', 'true', {
    domain: domain,
    expires: 14,
  })
}

const login = (token?: string, domain?: Maybe<string>) => {
  if (!token) {
    console.error('cannot login without token')
    return false
  }

  if (domain) {
    setCookies(token, domain)
  }

  if (process.env.NEXT_PUBLIC_COOKIE_DOMAIN) {
    setCookies(token, process.env.NEXT_PUBLIC_COOKIE_DOMAIN)
  } else {
    setCookies(token)
  }
}

export default login

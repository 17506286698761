const Zoom = ({ size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 48 48"
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#2D8CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.087 15.9353L31.3043 20.884V27.135L38.087 32.0837C38.5669 32.4503 39.1304 32.5636 39.1304 31.5628V16.4562C39.1304 15.5638 38.6782 15.4517 38.087 15.9353ZM8.34782 27.7567V16.4873C8.34782 16.0263 8.72491 15.6522 9.19007 15.6522H25.6254C28.1845 15.6522 30.2609 17.7077 30.2609 20.2433V31.5127C30.2609 31.9737 29.8838 32.3478 29.4186 32.3478H12.9833C10.4242 32.3478 8.34782 30.2923 8.34782 27.7567Z"
      fill="white"
    />
  </svg>
)

export default Zoom

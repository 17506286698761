import { useCurrentGroup } from 'lib/GroupContext'
import Link from 'next/link'
import { useIntl } from 'react-intl'

const HelpCenterLinks = () => {
  const { formatMessage, locale } = useIntl()
  const { marketplace } = useCurrentGroup()

  const b2cHelpUrl =
    locale === 'fr'
      ? 'https://intercom.help/mentorly/fr/collections/2780229-membre-du-marche'
      : 'https://intercom.help/mentorly/en/collections/2780229-marketplace-member'
  const b2bHelpUrl = `https://intercom.help/mentorly/${locale}/collections/807636-program-member`

  return (
    <Link href={marketplace ? b2cHelpUrl : b2bHelpUrl}>
      <a target="_blank" className="sm:mr-8">
        {formatMessage({ id: 'menu.helpCentre' })}
      </a>
    </Link>
  )
}

export default HelpCenterLinks

import { ReactNode } from 'react'

import H1 from './H1'
import H2 from './H2'
import H3 from './H3'
import H4 from './H4'
import H5 from './H5'

export type HeadingProps = {
  children: ReactNode
  className?: string
  [x: string]: any
}

export { H1, H2, H3, H4, H5 }

const GoogleSquare = ({ size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 72 72"
  >
    <polygon id="Square" fill="#EA4335" points="0 72 72 72 72 0 0 0" />
    <path
      d="M44.5177727,47.8144 C46.9724545,46.2677333 48.6879545,43.8912 49.3181818,40.8 L36.7136364,40.8 L36.7136364,31.7333333 L58.6113636,31.7333333 C58.9318182,33.12 59.1454545,34.6133333 59.1454545,36 C59.1454545,43.6490667 56.3393409,49.6138667 52.0249545,53.6181333 C47.9177955,57.4314667 42.4455,59.4666667 36.7136364,59.4666667 C27.4268636,59.4666667 19.4454091,54.1557333 15.6234545,46.3946667 L23.5322727,40.3562667 C25.3545909,45.888 30.5491591,49.8666667 36.7136364,49.8666667 C39.7323182,49.8666667 42.3995682,49.1488 44.5177727,47.8144 Z M15.6234545,25.6042667 C19.4454091,17.8442667 27.4268636,12.5333333 36.7136364,12.5333333 C42.6954545,12.5333333 48.0363636,14.7733333 52.2022727,18.4 L45.3659091,25.2266667 C43.0159091,23.3066667 40.025,22.1333333 36.7136364,22.1333333 C30.5491591,22.1333333 25.3545909,26.112 23.5322727,31.6437333 C23.0804318,33.0144 22.8272727,34.4757333 22.8272727,36 C22.8272727,37.5168 23.0772273,38.9728 23.5247955,40.3370667 L15.62025,46.3882667 C14.081,43.2608 13.2136364,39.7365333 13.2136364,36 C13.2136364,32.2602667 14.0820682,28.7338667 15.6234545,25.6042667 Z"
      fill="#FFFFFF"
    />
  </svg>
)

export default GoogleSquare
